import { render, staticRenderFns } from "./insurance.vue?vue&type=template&id=0443f954&scoped=true"
import script from "./insurance.vue?vue&type=script&lang=js"
export * from "./insurance.vue?vue&type=script&lang=js"
import style0 from "./insurance.vue?vue&type=style&index=0&id=0443f954&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0443f954",
  null
  
)

export default component.exports