<template>
  <div class="align">
    <div class="contentBlock">
      <div class="title">
        <h3>Страхование имущества</h3>
      </div>
      <div class="body">
        <p v-if="fileReady">Cтрахование квартиры по основному пакету рисков</p>
        <p v-else>Вы можете оформить <b>Быстрое страхование квартиры по основному пакету рисков</b> у нашего партнера –
          компании
          Страховой Дом ВСК (САО «ВСК») на максимально выгодных условиях. Для уточнения условий и оформления страхового
          продукта обратитесь к своему агенту</p>

        <div>
          <loader v-if="loading"></loader>
          <div v-else>
            <div v-if="fileReady" class="linksInsurance">
              <a :href="'/api/assets/pdf/insp/' + fileName + '.pdf'" target="_blank">Скачать страховой полис</a>
              <!--          <a href="/file/BCK19220190515.pdf" target="_blank">Правила №192 комплексные правила страхования (в-->
              <!--            действ ред. от 15.05-->
              <!--            .2019)</a>-->
              <a href="/file/insurance/go19220190515.docx" target="_blank">Условия страхования</a>
              <a href="https://www.vsk.ru/about/today/financial_report/#?top" target="_blank">Документы страховой
                компании</a>
            </div>
            <div v-else>
              <div v-if="insuranceAccessNotReady" class="errInsuranceWrap">
                <p>{{insuranceErrMsg}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="contentBlock">
      <div class="title">
        <h3>Страхование жизни</h3>
      </div>
      <div class="body">
        <p v-if="fileReadyLife">
          Полис-оферта комбинированного страхования от несчастных случаев и болезней</p>
        <p v-else>
          Вы можете оформить <b>комбинированное страхование от несчастных случаев и болезней</b> у нашего
          партнера –
          компании
          Страховой Дом ВСК (САО «ВСК») на максимально выгодных условиях. Для уточнения условий и оформления страхового
          продукта обратитесь к своему агенту</p>

        <div>
          <loader v-if="loadingLife"></loader>
          <div v-else>
            <div v-if="fileReadyLife" class="linksInsurance">
              <a :href="'/api/assets/pdf/insl/' + fileName + '.pdf'" target="_blank">Скачать страховой полис</a>
              <a :href="'/KID'" target="_blank">Ключевой информационный документ об условиях добровольного страхования</a>

<!--              <a href="/file/insurance/go19220190515.docx" target="_blank">Условия страхования</a>-->
              <a href="https://www.vsk.ru/about/today/financial_report/#?top" target="_blank">
                Документы страховой компании</a>
            </div>
            <div v-else>
              <div v-if="insuranceAccessNotReadyLife" class="errInsuranceWrap">
                <p>{{insuranceErrMsgLife}}</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div>
      <div v-if="showContacts" class="contactList">
        <contact></contact>
      </div>
    </div>
  </div>

</template>

<script>
  import {mapGetters} from 'vuex'
  export default {
    name: "insurance",
    data() {
      return {
        loading: true,
        loadingLife:true,
        fileReady: false,
        fileReadyLife:false,
        insuranceAccessNotReady: false,
        insuranceAccessNotReadyLife: false,
        fileName: localStorage.getItem('UserID'),
        insuranceErrMsg: '',
        insuranceErrMsgLife: '',
        showContacts: false,
      }
    },
    components: {
      loader: () => import('@/components/other/loader'),
      contact: () => import('@/views/Contact'),
    },
    methods: {
      saveInsuranceFile(res) {
        this.$myApi.singleRequest({
          action: 'savePdf',
          pdf: res.EaE_file_data,
        }).then((res) => {
          this.fileReady = true;
          this.loading = false;
        }).catch((res) => {
          alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
        });
      },
      saveInsuranceFileLife(res) {
        this.$myApi.singleRequest({
          action: 'savePdfLife',
          pdf: res.EaE_file_data,
        }).then((res) => {
          this.fileReadyLife = true;
          this.loadingLife = false;
        }).catch((res) => {
          alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
        });
      },
      insuranceFileCheck() {
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'insuranceInfo',
        }).then((res) => {
          this.saveInsuranceFile(res);
        }).catch(res => {
          this.insuranceAccessNotReady = true;
          this.loading = false;
          try {
            if ( res.errorCode > 0 || res.errorCode !== undefined ) {
              alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
            }
            this.insuranceErrMsg = res.errorMsg;
            switch (res.errorCode) {
              case 3:
                this.showContacts = true;
                break;
              default:
                break;
            }
          } catch (e) {
            alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
          }

        });
      },
      insuranceFileCheckLife() {
        console.log('insuranceFileCheckLife');
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'insuranceInfoLive'
        }).then((res) => {
          this.saveInsuranceFileLife(res);
        }).catch(res => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
          this.insuranceAccessNotReadyLife = true;
          this.loadingLife = false;
          try {
            this.insuranceErrMsgLife = res.errorMsg;
            switch (res.errorCode) {
              case 3:
                this.showContacts = true;
                break;
              default:
                break;
            }
          } catch (e) {
            console.log(e);
            alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
          }

        });
      },
      fileIsset() {
        this.$myApi.singleRequest({
          action: 'isIssetInsurancePdf'
        }).then((res) => {
          //если дата файла больше чем дата первого платежа условно означае,что файл под новый договор
          if (res.fileIsset && res.fileTime > this.activeDealData('PayGraphic')[0].pgPaysDate) {
            this.fileReady = true;
            this.loading = false;
          } else {
            this.insuranceFileCheck();
          }
        })
        .catch((res) => {
          alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
        });
      },
      fileIssetLife() {
        console.log('fileIssetLife');
        this.$myApi.singleRequest({
          action: 'isIssetInsurancePdfLife'
        }).then((res) => {
        //если дата файла больше чем дата первого платежа условно означае,что файл под новый договор
          if (res.fileIsset && res.fileTime > this.activeDealData('PayGraphic')[0].pgPaysDate) {
            this.fileReadyLife = true;
            this.loadingLife = false;
          } else {
            this.insuranceFileCheckLife();
          }
        })
        .catch((res) => {
          alert('Произошла ошибка обработки, мы уже её исправляем. Попробуйте позже');
        });
      }
    },
    computed:{
      ...mapGetters(['activeDealObj','activeDealData'])
    },
    watch:{
      activeDealObj(newVal){
        if (newVal.Demand > 0) {
          this.fileIsset();
          this.fileIssetLife();
        }
      }
    },
    mounted() {
      this.$parent.selectedSection = 0
      if(this.activeDealObj.Demand > 0) {
        this.fileIsset();
        this.fileIssetLife();
      }
    }
  }
</script>

<style scoped>
  .linksInsurance {}
  .linksInsurance a {display: block;margin: 2vw;}
  .errInsuranceWrap {margin: 3vw 0;}
  .errInsuranceWrap p {border: 1px dashed #0f5740;text-align: center;padding: 3vw;font-size: 6vw}
  @media screen and (min-width: 760px) {
    .linksInsurance {}
    .linksInsurance a {margin: 1vw 0;}
    .errInsuranceWrap {margin: 1.4vw 0;}
    .errInsuranceWrap p {font-size: 3vw}
    /deep/ .contactList .layout {width: calc(100% - 10vw)}
  }
  @media screen and (min-width: 1200px) {
    .linksInsurance {}
    .linksInsurance a {margin: 10px 0;}
    .contactList {}
    /deep/ .contactList .layout {width: 400px;margin: 0;}
    .errInsuranceWrap {padding: 20px 0;}
    .errInsuranceWrap p {font-size: 22px;padding: 20px;}
  }
</style>
